/* .Sidebar-Navigation__SubList {
    padding-left: 30px !important;
  } */
.Sidebar-Navigation__ListItem {
    display: flex;
    align-items: center;
    padding:6px 20px;
  }
.Sidebar-Navigation__ListItem .menusName {
    padding-left: 5px;
}
.Sidebar-Navigation {
  position: fixed;
  top: auto;
  left: auto;
  z-index: 100;
  width: 240px;
  height: 100%;
  transition: 0.5s all;
}
.Sidebar-Navigation__SubListItems .Sidebar-Navigation__Item {
  padding-left: 44px;
  padding-right: 8px;
}
.Sidebar-Navigation__SubListItems a {
  display: block;
}
/* .Sidebar-Navigation.nav-slided {
  right: 0px !important;
  top: 0;
  border-right: unset !important;
  background-color: #fff;
} */
.nav-slided .Sidebar-Navigation {
  right: 0 !important;
  background: #fff;
}
.Sidebar-Navigation > ul{
    padding: 16px 0;
}

.Sidebar-Navigation__List--item {
  padding-bottom: 10px;
}
.Sidebar-Navigation__SubList .menusName {
  font-size: 14px;
  line-height: 24px;
}
.crossIcon {
  font-size: 34px;
  position: absolute;
  top: 0;
  right: 16px;
  cursor: pointer;
  display: none;
}
.header-section__item.sidebar {
  display:none;
}
 .Sidebar-Navigation__List--item > a{
  display: block;
}
.container.darkMode .Sidebar-Navigation__SubListItems:hover,
.container.darkMode .Sidebar-Navigation__List--item > a:hover{
  background: #080833;
}
.Sidebar-Navigation__SubListItems:hover,
.Sidebar-Navigation__List--item > a:hover {
  background: #c3c3c3;
}
/* .crossIcon svg {
  background: #fafafa;
  padding: 8px;
  border: 1px solid #babfc3;
  border-radius: 6px;
} */

@media screen and (max-width:992px){
  .crossIcon {
    top: 10px;
    /* font-size: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: 1px solid #babfc3;
    border-radius: 6px; */
    box-shadow: 0px 2px 3px rgb(4 4 7 / 10%);
    padding: 10px;
    border-radius: 50%;
    margin-left: 8px;
    transition: 0.5s all;
    font-size: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.header-section__item.sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-section__item.sidebar .logo {
  padding-top: 10px;
  max-width: 120px !important;
}
.Sidebar-Navigation__List {
  padding-top: 10px;
}
}