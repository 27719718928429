@import url('https://fonts.googleapis.com/css2?family=Aclonica&family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lateef:wght@200;300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(241, 242, 244, 1);
}

:root{
  --darkmodebg : #30304d;
}
/* @import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap'); */
*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
 }
 .mt-0{
  margin-top: 0 !important;
 }
 .mt-10{
  margin-top: 10px !important;
 }
 .mt-20{
  margin-top: 20px !important;
 }
 .mb-10{
  margin-bottom: 10px !important;
 }
 .my-10{
  margin-block:10px;
 }
 .mb-20{
  margin-bottom: 20px !important;
 }
 .fs-20{
  font-size: 20px;
 }
 .fs-30{
  font-size: 30px;
 }

 .font-weight-bold{
  font-weight: 700;
 }
 .d-flex{
  display: flex;
 }
 .d-inline-block{
  display: inline-block;
 }
 .urduFont{
  font-family: "Lateef", serif;
 }
 
 .contentSection-card.urduLangActive,
 .contentSection-card.urduLangActive p, 
 .contentSection-card.urduLangActive h4 {
  text-align: right;
  font-family: "Lateef", serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px !important;
}
.contentSection-card.urduLangActive h4 {
  font-weight: 700;
  direction: rtl;
}
a {
  color: unset;
  text-decoration: none;
}
a[href="/"] {
  cursor: auto;
}
a[title="Home"] {
  cursor: pointer;
}
.container ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
}
body.active {
  overflow: hidden;
}
.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 6%) 0px 5px 15px;
  position: relative;
}
body:has(.container.nav-slided), body:has(.custom-modal){
overflow-y: hidden;
}
body:has(.custom-modal) .Sidebar-Navigation > ul{
  filter: blur(2px);
}
.container.nav-slided:after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #0000009e;
  height: 100%;
  width: 100%;
  z-index: 99;
  backdrop-filter: blur(3px);
  pointer-events: all;
}
.m-auto{
  margin: auto;
}
.text-center{
  text-align: center;
}
.contain {
  margin-left: 240px;
  padding: 20px;
  border-left: 1px solid #c3c3c3;
  transition: 0.3s all;
  min-height: calc(100vh - 205px);
}
.contentSection-card + .contentSection-card {
  margin-top: 20px;
}
.mt-12{
  margin-top: 12px;
}
.fs-22{
  font-size: 22px;
}
.ml-20{
  margin-left: 20px;
}
.page-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 22px;
}
.backBtn {
  padding: 9px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 20px;
  border: 1px solid #abb1ba;
  display: inline-flex;
}
.backBtn:hover {
    background: #fff;
}
.page-header__content{
  margin-left: 20px;
}
.pagetitle {
  margin-bottom: 8px;
}

/*Button Style*/
.btn-btn-primary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 6px;
  font-family: inherit;
  background: inherit;
  cursor: pointer;
  transition: 0.3s all;
  border: 1px solid #FFF;
  color: #fff;
}

.btn-btn-primary:hover {
  background: #fff;
  color: #000;
}
.btn-btn-secondary {
  display: inline-block;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 6px;
  font-family: inherit;
  background: inherit;
  cursor: pointer;
  transition: 0.3s all;
  border: 1px solid #000;
  color: #000;
}

.btn-btn-secondary:hover {
  background: #000;
  color: #fff;
}

.referenceTag {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: #595959;
}

.custom_scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}
.custom_scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #80808052;
  border-radius: 10px;
  background: #f1f1f1;
}
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.custom_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2d9;
}
.contentSection:last-child .dash-btn-container {
  border-bottom: unset;
}
  
/*Shabe Barat*/
.imgSection {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.imgSection  img {
  max-width: 390px;
}

@media screen and (max-width:992px){
  .Sidebar-Navigation {
    right: -245px !important;
    top: 0 !important;
}
.searchInput {
  display: none;
}
.contain, .Footer {
  margin-left: 0px !important;
}
.contain {
  padding-left: 12px !important;
  padding-right: 12px !important;
  border-left: unset;
}
.container.nav-slided .header-section__item .logo {
  display: none;
}
.Footer {
  border-left:unset !important;
}
}




.h-38{
height: 38px;
}
.w-200{
  width: 200px;
  }
  .h-20{
    height: 20px;
    }
    .w-240{
      width: 240px;
      }
      .w-220{
        width: 220px;
        }
    .mb-8{
      margin-bottom: 8px;
    }

    .mt-60{
      margin-top: 60px;
    }
.bg-grey{
    background-color: #c6c6c6;
  }


  /* Dark Mode */
  .container.darkMode {
    color: #fff;
}

.container.darkMode .header-section {
  background: var(--darkmodebg);
}
.container.darkMode .logo,
.container.darkMode .mob-logo {
  filter: invert(1);
}

.container.darkMode .header-section {
  box-shadow: 0 0 3px #1a1a2f;
}

.container.darkMode .moonIcon,
.container.darkMode .hamburgerIcon,
.container.darkMode .crossIcon{
  box-shadow: 0 2px 3px #1a1a2f;
}

.container.darkMode .Dashboard,
.container.darkMode .contain,
.container.darkMode .changeLangSection
{
  background: var(--darkmodebg);
}

.container.darkMode .btn-btn-secondary {
  color: #fff;
  border-color: #fff;
}

.container.darkMode .Sidebar-Navigation {
  background: #2c2c41;
}


.container.darkMode .contain,
.container.darkMode .Footer {
  border-left: unset;
}

.container.darkMode .backBtn:hover {
  background: #363651;
}

.container.darkMode .contentSection-card,
 .container.darkMode .Footer {
  background: var(--darkmodebg);
  box-shadow: 0 2px 8px #1a1a2f;
  color: #fff !important;
}


.container.darkMode .sc-dmctIk{
  border-radius: unset;
}

.container.darkMode .rdt_Pagination {
  background: var(--darkmodebg);
  color: #fff !important;
}
.container.darkMode .sc-gikAfH  {
  color: #fff;
  fill: #fff;
}
/* .hIWEKa {
  top: -0px !important;
} */
/* data table */
.container.darkMode .sc-jrcTuL,
.container.darkMode .contentSection-card .rdt_TableHead .rdt_TableHeadRow {
  background: #282849  !important;
  box-shadow: 0 2px 3px #1a1a2f;
  color: #fff;
}




.container.darkMode .SliderSection .slick-prev, 
.container.darkMode .SliderSection .slick-next{
  color: #fff;
}

.container.darkMode .monthsection__item,
.container.darkMode .dailyTime__item,
.container.darkMode .btn-btn-secondary:hover,
.container.darkMode .SliderSection .slick-prev:hover, 
.container.darkMode .SliderSection .slick-next:hover{
  background: #282849;
}
.container.darkMode .dailyTime__item.active{
  color: #000;
}
.container.darkMode .referenceTag{
  color: #8383a6;
}


.card_container__Item{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding:10px;
}

.card_container{
  display:grid;
  grid-template-columns:repeat(2, 1fr);
  gap:15px
  
}


/* react table header sticky */
.rdt_TableHeadRow div[data-column-id="1"] {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 3;
}

.darkMode .rdt_TableHeadRow div[data-column-id="1"],
.darkMode .jfdhAf,.darkMode  .rdt_TableBody > div div[data-column-id="1"]{
  background:#282849;
}
.rdt_TableHead{
  z-index: 3 !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;  /* Customize loader color */
}

.changeLangSection label {
  padding-right: 10px;
}
.changeLangSection select, .changeDist select{
  padding: 4px 4px 4px 0;
  border-radius: 6px;
  border-color: #a0a0a0;
  background: #fff;
  cursor: pointer !important;
}
.changeLangSection select:focus , .changeDist select:focus {
  border-color: #a0a0a0;
  outline: unset;
}
.changeLangSection {
  padding: 12px;
  position: sticky;
  top: 83px;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.custom-modal {
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.custom-modal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  pointer-events: none;
  backdrop-filter: blur(3px);
}
span.icon-info {
  display: inline-flex;
  padding: 12px;
  background: #000;
  border: 1px solid #fff;
  border-radius: 50%;
}

span.icon-info svg{
fill: #fff;
}
.modal-content {
  max-width: 440px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 24px 20px;
  border-radius: 8px;
  z-index: 99;
  width: 100%;
  color: #000;
}
.modal-content .msg {
  margin-bottom: 22px;
  line-height: 24px;
  font-weight: 300;
}

.modal-content .btn-groups {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.modal-content .btn {
  padding: 12px 20px;
  border: 1px solid #eee;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s all;
}
.btn-confirm {
  background: #000 ;
  color: #fff;
}

.btn-confirm:hover {
  opacity: 0.7;
}
.btn-cancel {
  background: #fff ;
  color: #000;
}

.btn-cancel:hover {
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width:600px){
  .modal-content {
    max-width: 320px;
    box-sizing: border-box;
  }
img{
  width: 100%;
}

/* Data Table CSS */

.sc-hLBbgP {
  min-width: 75px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.fs-30{
  font-size: 24px;
 }
 .pagetitle {
  margin-bottom: 4px;
  font-size: 20px;
}
}