.SliderSection {
    margin-top: 15px;
    border-radius: 4px;
}

.SliderSection .slick-prev, .SliderSection .slick-next {
    top: unset;
    bottom: -60px;
    border-radius: 4px;
    color: #000;
    width: 30px;
    height: 30px;
    border: 1px solid #eee;
    transition: 0.3s all;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SliderSection  .slick-prev:before, .SliderSection  .slick-next:before{
    color: #000;
}
.SliderSection .slick-prev:hover, .SliderSection .slick-next:hover {
    color: #fff;
    background: #0000008f;
}

.SliderSection .slick-prev {
    right: 40px;
    left: unset;
}

.SliderSection .slick-next {
    right: 0;
}

@media screen and (max-width:992px){
    .SliderSection .slick-slide img{
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
    }
}